import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"
import { setQnairre } from "./qnairreSlice";
import useAuth from "../../hooks/useAuth";

// const usersAdapter = createEntityAdapter({})

// const initialState = usersAdapter.getInitialState()




export const qnairreApiSlice = apiSlice.injectEndpoints(
    
    {
    endpoints: (builder) => ({
        getQnairre: builder.query({
            query: () => '/users/qnnaire',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        //     transformResponse: responseData => {
        //         const loadedUsers = responseData.map(user => {
        //             user.id = user._id
        //             return user
        //         });
        //         return usersAdapter.setAll(initialState, loadedUsers)
        //     },
            providesTags: ['Qnairre']
        }),
        // addNewUser: builder.mutation({
        //     query: initialUserData => ({
        //         url: '/users',
        //         method: 'POST',
        //         body: {
        //             ...initialUserData,
        //         }
        //     }),
        //     invalidatesTags: [
        //         { type: 'User', id: "LIST" }
        //     ]
        // }),
        // getQnairre: builder.query({
        //     query: (id) => '/users/qnnaire',
        //     providesTags: (result, error, id) => [{ type: 'User', id }],
        // }),
        updateQnairre: builder.mutation({
            query: newQs => ({
                url: '/users/qnnaire',
                method: 'PATCH',
                body: {
                    "reqQs": newQs
                }
            }),
            invalidatesTags: ['Qnairre'],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    // data.message is a string you want it an object
                    const { data } = await queryFulfilled
                    const reqQs = data.userData.reqQs // has to be in this format for setQnairre to work properly
                    dispatch(setQnairre({reqQs}))
                } catch (err) {
                    console.log("error is", err)
                }
            }
        }),
    }),
})

export const {
    useGetQnairreQuery,
    // useAddNewUserMutation,
    useUpdateQnairreMutation,
} = qnairreApiSlice

