import React, { useState } from "react";

const NextButton = ({handleAnswer, question}) => {
   
  
  
  return (
    <div className="">
        <button type="submit" className="h-16 w-80 flex items-center justify-center rounded-2xl bg-green-400">
            Continue 
        </button>
    </div>

     // <form onSubmit={handleSubmit}>
    //   <div>
    //     <p>{question.text}</p>
    //     <input
    //       type={question.inputType}
    //       value={answer}
    //       onChange={handleChange}
    //       required
    //     />
    //   </div>
    //   <button type="submit">
    //       Next</button>
    //     </form>
    //   </>
    // )
  )
}
export default NextButton