import { createSlice } from '@reduxjs/toolkit'

const qnairreSlice = createSlice({
    name: 'qnairre',
    initialState: { curQnairre: {} },
    reducers: {
        setQnairre: (state, action) => {
            const { reqQs } = action.payload
            state.curQnairre = reqQs
        }
    }
})

export const { setQnairre} = qnairreSlice.actions

export default qnairreSlice.reducer

export const getCurQnairre = (state) => state.qnairre.curQnairre