import React from 'react'

function ErrorPage() {
  return (
    <div>
        <p> 404 Error: Sorry this page was not found!</p>
    </div>
  )
}

export default ErrorPage