const questionnaire = {
  "start": {
     component: "intro",
     next: "choosegoals",
     partNum: "1"
  },
  "choosegoals": {
    component: "chooseGoal",
    next: "",
    part: "Your Goal",
    partNum: "1"
  },
  "purchase": {
    component: "purchaseGoal",
    next: "riskintro",
    part: "Your Goal",
    partNum: "1"
  },
  "riskintro": {
    component: "riskintro",
    next: "",
    part: "Risk Comfort",
    partNum: "2"
  },
  "retirement":{

  }
}

// const questionnaire = 
// [
//   {
//     goal: "purchase",
//     text: "What best describes your investment goal?",
//     dataField: "goal",
//     inputType: "number", 
//     component: "intro"
//   },
//   {
//     id: 1,
//     text: "What best describes your investment goal?",
//     dataField: "goal",
//     inputType: "number", 
//     component: "intro"
//   },
//     {
//       id: 2,
//       text: "What best describes your investment goal?",
//       dataField: "goal",
//       inputType: "number", 
//       component: "chooseGoal"
//     },
//     {
//         id: 3,
//         text: "I take home __ __",
//         dataField: "payStub",
//         inputType: "number", 
//         component: "purchaseGoal"
//       },
//       {
//         id: 4,
//         text: "How much are your monthly expenses?",
//         dataField: "expenses",
//         inputType: "number", // Numeric input
//         help: {
//           rent: "number",
//           groceries: "number",
//           childcare: "number",
//           subscriptions: "number"
//         }
//       },
//       // risk tolerance 
//       { 
//         id: 5,
//         text: "Could you handle a loss of your principal",
//         dataField: "rent",
//         inputType: "text", 
//       }
// ]

export default questionnaire