import { Routes, Route } from "react-router-dom"
import Retirementcalc from "./components/Landingpage/Retirementcalc.js";
import Login from './components/Onboarding/Step0/Login'
import SignUp from './components/Onboarding/Step0/SignUp'
import FAQ from './components/FAQpage/FAQ';
import ErrorPage from "./components/404.js";

export function PublicRoutes() {
    return(
        <>
    {/* <Layout/> */}
    <Routes>
        <Route path="/retcalc" element={<Retirementcalc />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/*" element={<ErrorPage />} />
    </Routes>
    </>
    )
    
    

}