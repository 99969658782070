import React, { useState } from "react";

import ChooseGoal from "./Qnairre/ChooseGoal";
import PurchaseGoal from "./Qnairre/PurchaseGoal";
import Intro from "./Qnairre/Intro";
import riskIntro from "./Qnairre/RiskIntro";


const QnnaireForm = ({question, handleAnswer}) => {

  const Components = {
    chooseGoal: ChooseGoal,
    purchaseGoal: PurchaseGoal,
    intro: Intro,
    riskintro: riskIntro
  };

  const [answer, setAnswer] = useState({});

  const handleChange = (e) => {
    setAnswer(e.target.value);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass the answer to the parent component
    answer = {
      done: question.next
    }
    // if (question.inputType === "number") {
    //   handleAnswer(Number(answer)); // Convert to a number
    // } else {
    //   handleAnswer(answer)
    // }
    setAnswer({}); // Clear the input for the next question
  };
  let content = (
    <>
    <div> oops there's an error </div>
    </>
  )
    // const content = 
    // (
      if(typeof Components[question.component] !== undefined) {
        return React.createElement(Components[question.component],
          {handleAnswer: handleAnswer,
            question: question 
          }
        )
      } else {
        return content
      }
     
    //   <>
    // <form onSubmit={handleSubmit}>
    //   <div>
    //     <p>{question.text}</p>
    //     <input
    //       type={question.inputType}
    //       value={answer}
    //       onChange={handleChange}
    //       required
    //     />
    //   </div>
    //   <button type="submit">
    //       Next</button>
    //     </form>
    //   </>
    // )
    // return content 
  }
  export default QnnaireForm