import { createSlice } from '@reduxjs/toolkit'

const allocSlice = createSlice({
    name: 'alloc',
    initialState: { },
    reducers: {
        setInvData: (state, action) => {
            const { invData } = action.payload
            state.invData = invData
        }
    }
})

export const { setInvData } = allocSlice.actions

export default allocSlice.reducer

export const getInvData = (state) => state.alloc.invData