import React, { useState } from "react";
import NextButton from "./NextButton";
import InputBox from "./InputBox";

const PurchaseGoal = ({handleAnswer, question}) => {

    const curMonth = new Date().toLocaleString('default', { month: 'long' });
    const [selectedValue, setSelectedValue] = useState(curMonth);

    const currentYear = new Date().getFullYear(); // Current year
    const maxYear = currentYear + 100; // Maximum allowed year

    const [year, setYear] = useState(currentYear+5); // State to track the input value
    const [error, setError] = useState('');

    const [goal, setGoal] = useState({
        targetMonth: curMonth,
        targetYear: (currentYear+5),
    });

    const months = [
        "January", "February", "March", "April", "May",
        "June", "July", "August", "September",
        "October", "November", "December"
    ];

    const handleValueChange = (newValue) => {
        setGoal((prevGoal) => ({
          ...prevGoal,
          ...newValue,
        }));
    };

    const handleMonthChange = (e) => {
        const input = e.target.value;
        setGoal({...goal, targetMonth: input})
        console.log(goal)
        setSelectedValue(e.target.value)
    }   

    const handleChange = (e) => {
        const input = e.target.value;
        setGoal({...goal, targetYear: Number(input)})
        // Check if input is a valid integer within the range
        if (input === '' || /^[0-9]+$/.test(input)) {
            const parsed = parseInt(input, 10);

            if (!isNaN(parsed) && (parsed < (currentYear+5) || parsed > maxYear)) {
                setError(`Enter a number between ${currentYear+5} and ${maxYear}`);
            } else {
                setError('');
            }

            setYear(input); // Update state with raw input
        }
    };

    // const handleClick = () => {
    //     if (!error && year) {
    //         alert(`You entered: ${year}`);
    //     } else {
    //         alert('Please correct the input.');
    //     }
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        const answer = {
                done: question.next,
                newQs: goal
            }
        handleAnswer(answer)
        // setAnswer({}); // Clear the input for the next question
      };

    return (
        <>
            <form onSubmit={handleSubmit}>
            <div className="w-full flex flex-col">
                <div className="h-28 w-full flex flex-col justify-center">
                    <div>
                        <p className="text-4xl">I will need to save</p>
                    </div>
                    <div className="flex items-center h-12 ">
                        <InputBox type="number" placeholder="10,000" width="w-40" onValueChange={handleValueChange} keyName="goalAmount"/>
                        <div className=" flex flex-col justify-center items-center  w-12 ">
                            {/*  TODO: Adjust entry buttons  */}
                            <div className="w-6 h-[18px] bg-green-500 clip-path-triangle mb-1"> </div>
                            <div className="w-6 h-[18px] bg-red-500 clip-path-triangle rotate-180"></div>
                            {/* finish adjust buttons */}
                        </div>
                        <div>
                            <p className="text-4xl"> by </p>
                        </div>

                    </div>

                </div>
                <div className="mt-5" >
                    {/* month button, missing check for month befoer cur1 if on the current year prolly at the end when submitting*/}
                    <select value={selectedValue} onChange={handleMonthChange} className="shadow-md h-20 w-48 rounded-xl text-2xl flex bg-white border px-6 text-gray-500 block  border-slate-400"
                        style={{
                            appearance: 'none',
                            overflowY: 'auto',
                            background: 'white no-repeat 96% url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")',
                        }}>
                        {months.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>

                    {/* year button  */}

                    <div className="mt-3">
                        <input
                            type="text"
                            value={year}
                            onChange={handleChange}
                            className="border rounded-xl pl-6 text-2xl h-20 w-48  text-gray-500 border
                            border-slate-400 shadow-md focus:outline-none focus:ring-2 focus:ring-green-500"
                            placeholder="Enter a year"
                        />
                        <div className="w-full h-6">
                            {error && <p className="text-red-500 text-sm pt-4">{error}</p>}
                        </div>
                        
                        {/* <button
                            onClick={handleClick}
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition"
                        >
                            Submit
                        </button> */}
                    </div>
                </div>

                {/* user goal input */}

                <div className="mt-1 h-28 w-full flex flex-col justify-center">
                    <div>
                        <p className="text-4xl">for my</p>
                    </div>
                    <div className="flex items-center h-12 ">
                        <InputBox type="text" placeholder="first house" width="w-40" onValueChange={handleValueChange} keyName="goalName"/>
                    </div>
                </div>
                <div className="my-6">
                    <p className="text-xl text-gray-500">You can always change this later in settings</p>
                </div>
                <div className="w-full flex justify-center">
                    <NextButton />
                </div>
            </div>
            </form>
        </>

    )
}
export default PurchaseGoal