import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouse, faPersonCane, faCloudShowersHeavy} from "@fortawesome/free-solid-svg-icons"
import NextButton from "./NextButton";

const ChooseGoal = ({handleAnswer, question}) => {
    const [goal, setGoal] = useState({});
    const [flag, setFlag] = useState(0);

    const handleClick = (goalType) => {
        if (flag === 1) {
            setFlag(0)
        }
        setGoal(goalType); // Update the state to "mygoal"
      };

    const handleSubmit = (e) => {
        e.preventDefault();
         
        // Pass the answer to the parent component
        // if (question.inputType === "number") {
        //   handleAnswer(Number(answer)); // Convert to a number
        // } else {
        //   handleAnswer(answer)
        // }
        if (Object.keys(goal).length === 0){
            setFlag(1)
        } else {
            const answer = {
                done: goal.done,
                newQs: {goalType: goal.goalType}
            }
            handleAnswer(answer)
        }
        
        // setAnswer({}); // Clear the input for the next question
      };
    const invGoals = [
        {
          goalType: "PURCHASE",
          done: "purchase",
          icon: faHouse,
          goal: "Save for a large purchase",
          explanation: "I’m looking to save for a home, a car, or another large purchase",
        },
        {
            goalType: "RETIREMENT",
            icon: faPersonCane,
            goal: "Plan for retirement",
            explanation: "I want to let my money grow for a while until I hit retirement age ",
          },
          {
            goalType: "EMERGENCY",
            done: "purchaseGoal",
            icon: faCloudShowersHeavy,
            goal: "Have an emergency fund",
            explanation: "I want to let my money grow for a while until I hit retirement age",
          },
      ];

  return (
    <div>
      <form onSubmit={handleSubmit}> 
      <div className="w-80 ">
        <p className="text-4xl text-slate-800">
            I'm looking to: 
        </p>
        <div className="mt-8">
            {invGoals.map((item,index)=> (
                <div
                className={`h-32 w-full border rounded-2xl shadow-lg px-4 py-3 mb-3
                    ${(goal?.goalType === item.goalType) ? `border-green-500` : `border-slate-200`}`}
                onClick={ () =>
                    handleClick(
                    {   done: item.done,
                        goalType: item.goalType
                    }
                )
                }
                >
                    <div className="flex items-center font-semibold">
                        <div className="w-8 h-8 bg-gray-300 rounded-full mr-2 flex items-center justify-center">
                            <FontAwesomeIcon icon={item.icon} className="text-green-500 h-5 mb-0.5"/>
                        </div>
                        <p className="text-xl">{item.goal}</p>
                    </div>
                    <div> 
                        <p className="text-slate-500 pl-10 mt-2 text-left">{item.explanation} </p>
                    </div>
                </div>
            ))}
        </div>
        <div className="mt-16">
            <NextButton/>
        </div>
        {(flag === 1) ? <p className="text-red-500"> please pick a goal above </p> : <></>}
    </div>
    </form>
    </div>
  
  )
}
export default ChooseGoal