import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import { getCurQnairre } from "../users/qnairreSlice"
import { useSelector } from "react-redux"

const RequireOnboard = () => {
    const location = useLocation()
    const qnaire = useSelector(getCurQnairre);
    const onboarded = qnaire.done; 
    const navStr = "/qnairre"

    const content = (
        (onboarded === "done")
            ? <Outlet />
            : <Navigate to="/qnairre" state={{ from: location }} replace />
    )

    return content
}
export default RequireOnboard