import React, { useState } from "react";
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPepperHot, faShield } from "@fortawesome/free-solid-svg-icons";

import { useUpdateAllocMutation } from "../../../../features/users/allocApiSlice.js";
import { getCurQnairre } from "../../../../features/users/qnairreSlice.js";
import NextButton from "./NextButton.js"

import { getMonthNumber } from "../../../helperfuncs.js";

const RiskIntro = () => {


  // get time data from qnairre

  let qnaire = useSelector(getCurQnairre)
  const index = qnaire.goals.findIndex(obj => Object.keys(obj)[0] === "goal1");
  let goalYear = qnaire.goals[index].goal1.targetYear
  let goalMonth = getMonthNumber(qnaire.goals[index].goal1.targetMonth.toLowerCase())

  const [selected, setSelected] = useState(null);
  const [sliderValue, setSliderValue] = useState(3); // Default value

  const handleClick = (id) => {
    setSelected(id);
    if (id === 2 && (sliderValue > 5)){
      setSliderValue(5)
    }
  };

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value); // Update state with the slider value
  };

  const [updateAlloc, {
  }] = useUpdateAllocMutation()

  // make post request to get risky portfolio allocation 

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Pass the answer to the parent component
    // if (question.inputType === "number") {
    //   handleAnswer(Number(answer)); // Convert to a number
    // } else {
    //   handleAnswer(answer)
    // }
    // const answer = {
    //     // done: question.next,
    //     newQs: {"goal1": {}}
    // }
    const queryString = `?riskScore=${sliderValue.toString()}` 
    + `&years=${goalYear}` + `&months=${goalMonth}`
    console.log(queryString)
    try {
      await updateAlloc(queryString)
    } catch {
      console.log("oopsie")
    }
    // handleAnswer(answer)
    // setAnswer({}); // Clear the input for the next question

  };

  return (
    <div>
      <form onSubmit={handleSubmit}>  
      <div>
        <div className="h-24 flex justify-center items-center">
          <p className="text-2xl text-slate-600">Great job! Let's figure out your risk capacity... </p>
        </div>
        <div className="h-24 flex justify-center items-center">
          <p className="text-2xl text-slate-600">
            Can you tolerate a 20% decline in the value of your
            investments?
          </p>
        </div>
      </div>
      {/* yes,no button */}
      <div className="w-full flex justify-center h-11 my-4  ">
        <div className="flex gap-4 ">
          {[1, 2].map((id) => (
            <div className="flex items-center ">
              <div className={`border border-2 w-6 h-6 
         rounded-full flex justify-center items-center 
         ${selected === id ? " border-slate-600 bg-green-500 shadow-md shadow-green-300" : "border-slate-600"}`}
                key={id}
              >
                <button
                  key={id}
                  onClick={() => handleClick(id)}
                  className={`w-full h-full rounded-full border border-3 border-slate-500  ${selected === id ? "" : ""
                    }`}
                  style={{
                    WebkitAppearance: 'none',
                    MozAppearance: 'none'
                    // overflowY: 'auto',
                    // background: 'white no-repeat 96% url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")',
                  }}
                ></button>
              </div>
              <p className="text-xl ml-2 pb-1">{`${(id === 1) ? "yes" : "no"} `}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={`h-max ${(selected === null) ? "hidden" : ""}`}>
        {/* risk tolerance slider  */}
        <div className={`h-max ${(selected === null) ? "hidden" : ""}`}>
          <p className="text-lg text-slate-600 mb-4"> Drag the slider to adjust risk capacity</p>

          <div className="w-full h-14 rounded-lg flex flex-col items-center gap-y-2">
            <div className=" flex items-center justify-center">
              <p className="text-lg"> Current Risk capacity: {sliderValue} </p>
            </div>
            <div className="flex items-center gap-x-3">
            <FontAwesomeIcon icon={faShield} className="text-green-600 h-6"/>
            <input
              type="range"
              min="1"
              max={`${(selected !== 1) ? "5" : "10"}`}
              step="1"
              defaultValue={sliderValue}
              onChange={handleSliderChange}
              className="w-56 h-6 rounded-xl bg-gradient-to-r from-green-500 to-red-500 accent- bg-transparent"
              placeholder="Select an investment value"
              style={
                {
                  WebkitAppearance: 'none',
                  MozAppearance: 'none',
                  // overflowY: 'auto',
                  // background: 'white no-repeat 96% url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")',
                }
              }
            />
             <FontAwesomeIcon icon={faPepperHot} className="text-red-600 h-6"/>
           </div>
          </div>
          <div>
            <div className="my-10 flex flex-col gap-y-4">
              <div className="flex">
              <FontAwesomeIcon icon={faPepperHot} className="text-red-600 h-6 mr-4"/>
              <p className="text-lg"> More risk more possible reward</p>
              </div>
              <div className="flex">
              <FontAwesomeIcon icon={faShield} className="text-green-600 h-6 mr-4"/>
              <p className="text-lg"> I don't want my investment to fluctuate in value</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center"> 
      <NextButton />
      </div>
      </div>
      
      </form>
    </div>

  )
}
export default RiskIntro
