import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faCircleExclamation, faWallet } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../../../hooks/useAuth";
import NextButton from "./NextButton";

const Intro = ({handleAnswer, question}) => {
    // const [answer, setAnswer] = useState({});
    
    //   const handleChange = (e) => {
    //     setAnswer(e.target.value);
    //   };
      
      const handleSubmit = (e) => {
        e.preventDefault();
        // Pass the answer to the parent component
        // if (question.inputType === "number") {
        //   handleAnswer(Number(answer)); // Convert to a number
        // } else {
        //   handleAnswer(answer)
        // }
        const answer = {
            done: question.next,
            newQs: {"goal1": {}}
        }
        console.log(question.next, "moreno que ", answer)
        handleAnswer(answer)
        // setAnswer({}); // Clear the input for the next question
      };

    const {firstName} = useAuth()

    function capFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
    }

  return (
    
    <div className="w-80">
        <div className="h-14  flex items-center ">
            <p className="text-4xl">
                Hi {capFirstLetter(firstName)}
            </p>
        </div>
        <div className="h-14  flex items-center mt-12">
            <p className="text-2xl">
                Let’s build the right plan for you by getting to know your: 
            </p>
        </div>
        {/* Goal section  */}
        
        <div className="w-full ">
            <div className="flex h-12 items-center between  mt-12 ">
                <p className="text-4xl  text-gray-800 w-40 mr-16" > 1. Goal </p>
                <FontAwesomeIcon icon={faBullseye} className="text-red-300 h-12
                "/>
            </div>
            <div className="flex flex-col my-4 space-y-2">
                <div className="w-3 h-3 bg-gray-300 rounded-full"></div>
                <div className="w-3 h-3  bg-gray-300 rounded-full"></div>
                <div className="w-3 h-3  bg-gray-300 rounded-full"></div>
            </div>
            <div className="flex h-18 items-center  ">
                <p className="text-4xl text-gray-800 w-40  mr-16"> 2. Risk Tolerance </p>
                <FontAwesomeIcon icon={faCircleExclamation} className="text-red-300 h-12
                "/>
            </div>
            <div className="flex flex-col my-4 space-y-2">
                <div className="w-3 h-3 bg-gray-300 rounded-full"></div>
                <div className="w-3 h-3  bg-gray-300 rounded-full"></div>
                <div className="w-3 h-3  bg-gray-300 rounded-full"></div>
            </div>
            <div className="flex h-12 items-center  mb-12 ">
                <p className="text-4xl text-gray-800  w-40 mr-16"> 3. Budget </p>
                <FontAwesomeIcon icon={faWallet} className="text-red-300 h-12
                "/>
            </div>
            <form className="" onSubmit={handleSubmit}>

            <div className="">
                <button type="submit" className="h-16 w-80 flex items-center justify-center rounded-2xl bg-green-400">
                    Continue 
                </button>
            </div>
            </form>
            {/* <NextButton handleAnswer={handleAnswer} question={question}/> */}
        </div>
        
    </div>
  )
}
export default Intro