import { useState, useEffect } from "react"
import { Link } from 'react-router-dom'
import { useAddNewUserMutation } from "../../../features/users/usersApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { ROLES } from "../../../config/roles"
import usePersist from '../../../hooks/usePersist' // added for (Signup -> Welcome) functionality

import { useDispatch } from 'react-redux'
import { setCredentials } from '../../../features/auth/authSlice'
import { useLoginMutation } from '../../../features/auth/authAPIslice'

import logo from '../../Finturalogo.svg'
// import { useDispatch } from 'react-redux'
// import { setCredentials } from './authSlice'

const USER_REGEX = /^[A-z0-9]{3,20}$/
const PWD_REGEX = /^(?=.*\d)(?=.*[!@#$%^&])[A-Za-z\d!@#$%^&*]{8,25}$/ 
const hasLetterRegex = /[a-zA-Z]/;
const hasSpecialCharRegex = /[!@#$%^&]/;
const hasNumberRegex = /\d/;

const NewUserForm = () => {

    const [addNewUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewUserMutation()

    const [login] = useLoginMutation()

    const navigate = useNavigate()
    const dispatch = useDispatch()


    const [finturoUsername, setUsername] = useState('')
    const [validUsername, setValidUsername] = useState(false)
    const [finturoPassword, setPassword] = useState('')
    const [finturoConfirmPassword, setFinturoConfirmPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false)
    const [roles, setRoles] = useState(["User"])
    const [persist, setPersist] = usePersist() // added for (Signup -> Welcome) functionality
    const [hasInteracted, setHasInteracted] = useState(false);
    const [hasInteractedPWD, setHasInteractedPWD] = useState(false);
    const [isInfoDivVisible, setInfoDivVisibility] = useState(false);
    const [hasInteractedconfPWD, setHasInteractedconfPWD] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [firstName, setFirstname] = useState('')
    const [dateOfBirth,setAge] = useState('')
    const [accountEmail,setaccountEmail] = useState('')

    const [FXAIX, setFXAIX] = useState(0);
    const [SPAXX, setSPAXX] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [monthlyContribution, setMonthlyContribution] = useState(0);

    const [cleanState, setCleanState] = useState(false);

    const buttonElement = document.getElementById('subBut'); // Replace with your button's ID or ref

    useEffect(() => {
        setValidUsername(USER_REGEX.test(finturoUsername))
    }, [finturoUsername])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(finturoPassword))
    }, [finturoPassword])

    useEffect(() => {
        /* 
        REASON FOR CLEANSTATE ADDING:
        refer to following code.

        const onSaveUserClicked = async (e) => {
            ...
            setPersist(true)
            await addNewUser({ finturoUsername, finturoPassword })

            const { accessToken } = await login({ finturoUsername, finturoPassword }).unwrap()
            dispatch(setCredentials({ accessToken }))

            setCleanState(true);
            navigate('/dash')
            ...
        }
        
        if this useEffect ran only when isSuccess was true (and not cleanState), 
        then if addNewUser() returns successfully, isSuccess would be set to true 
        and the state would be cleared right away. However, this is a problem because we still have
        login and dispatch to run before navigating to the next page. While login and dispatch run, 
        the user is briefly displayed error messages (since all fields are now empty strings
        since state was cleared) before being navigating to the next page. cleanState ensures that 
        there is no wait time between clearing the state and navigating to the next page, 
        eliminating brief error message displays.
        */
        if (isSuccess && cleanState) { 
            setUsername('')
            setPassword('')
            setRoles([])
            buttonElement.innerHTML = "Loading..."
            buttonElement.disabled = true; 
        }
    }, [isSuccess, navigate])

    useEffect(() => {
        let timeoutId;

        if (showMessage) {
            timeoutId = setTimeout(() => {
                setShowMessage(false);
            }, 3000);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [showMessage]);

    // let subBool = true 
    const handleButtonClick = () => {
        if (canSave) {
            // Change the button type to "submit"
            if (buttonElement) {
                buttonElement.type = 'submit';
            }
            // setShowMessage(true);
        } else{
            setShowMessage(true);
        }
    };

    const onUsernameChanged = e => {
        setHasInteracted(true);
        setUsername(e.target.value.toLowerCase());
    }
    const onPasswordChanged = e => {
        setHasInteractedPWD(true)
        setPassword(e.target.value)
    }
    const onConfirmPWDChanged = e => {
        setHasInteractedconfPWD(true)
        setFinturoConfirmPassword(e.target.value)
    }
    const onNameChanged = e => {
        // setHasInteracted(true);
        setFirstname(e.target.value);
    }
    const onAgeChanged = e => {
        // setHasInteracted(true);
        setAge(e.target.value);
    }
    const onEmailChanged = e => {
        setaccountEmail(e.target.value);
    }

    // Function to show the info div when the password input is in focus
    const showInfoDiv = () => {
        setInfoDivVisibility(true);
    };
    
    // Function to hide the info div when the password input loses focus
    const hideInfoDiv = () => {
        setInfoDivVisibility(false);
    };

    const canSave = [validUsername, validPassword].every(Boolean) && !isLoading && (finturoPassword == finturoConfirmPassword )

    const onSaveUserClicked = async (e) => {
        e.preventDefault()
        // try {

        // } catch {

        // }
        if (canSave) {
            setPersist(true)
            await addNewUser({ 
                finturoUsername, 
                finturoPassword, 
                firstName, 
                dateOfBirth, 
                accountEmail, 
                FXAIX, 
                SPAXX, 
                startDate, 
                monthlyContribution
            }) 
            const { accessToken } = await login({ finturoUsername, finturoPassword }).unwrap() //only happens if sucessful
            dispatch(setCredentials({ accessToken }))
            setCleanState(true);
            navigate('/qnairre') 
        }
    }

    const options = Object.values(ROLES).map(role => {
        return (
            <option
                key={role}
                value={role}
            > {role}</option >
        )
    })

    const errClass = isError ? "text-center text-red-500 text-xl" : "offscreen"
    const validUserClass = hasInteracted && !validUsername ? 'bg-red-300 -lg ' : ''
    const validPwdClass = hasInteractedPWD && !validPassword ? 'bg-red-300 rounded-lg' : ''
    const myBoolean = true;

    // let message = 'Error';
    // const message = !validUsername ? `${variable} ` : ''

    const checkMessage = (finturoUsername, validUsername) => {
        let message = '3-20 letters and numbers only';
        if ( (finturoUsername.length < 3) ){
            message = 'Username too short'
        } else if ((finturoUsername.length > 20)) {
            message = 'Username too long'
        } else if (!validUsername){
            const nonAlphanumericChar = finturoUsername.match(/[^A-z0-9]/)
            if ((nonAlphanumericChar !== null)){
                if (nonAlphanumericChar[0] === ' '){
                    message = 'invalid character: space'
                } else {
                    message = 'invalid character: ' + nonAlphanumericChar
                }
            } 
            
        }
        return message;
    };

    const content = (
        <>
        <div className="font-Walsh2 h-screen w-screen px-1 absolute side-phone:pb-0 pb-20 bg-Finanzaorange">
            <div className='  flex flex-col items-center relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <div className=' flex flex-col items-center px-3 py-3  border-gray-950 rounded-2xl '> 
            <div className='font-bold text-2x1 cursor-pointer flex items-center font-[Poppins] text-gray-800 side-phone:mb-0 mb-10 mr-2' >
            <Link to="/">
                <div className="flex ">
                <img className='h-32 w-32 mr-1' alt='Finturo logo' src={logo}></img>
                {/* <p className="text-FinanzaGreen font-body2 -ml-1" style={{fontSize: '1.875rem'}}>  Finturo </p> */}
                </div>
                   
            </Link>
             </div>

               <header className="text-center font-Walsh-2">
               <div className="form__title-row ">
                            <div className="pl-3">
                                    <div className="">
                                        <h2 className=" text-xl font-semibold">Create Your Account Now</h2>
                                    </div>
                                <div className=" w-10/12 relative  left-1/2 transform -translate-x-1/2  ">
                                    <h4 className="pt-3  text-center text-sm"> Enter your information below to create your new Finturo account! </h4>
                                 </div>
                            </div>
                           
                           
                            
                </div>
               </header>
                <main>
                         {/* this is the errorHandler */}
                    <p className={errClass} >{error?.data?.message}</p>
                    <form className="flex flex-col side-phone:pt-8 pt-12 mx-16" onSubmit={onSaveUserClicked}>
                    <div className="flex justify-between font-bold">
                                    <h1>
                                        First Name
                                    </h1>
                                    <h1>
                                        Birthday
                                    </h1>
                                </div>
                            <div className="grid "  style={ {gridTemplateColumns : "55% 45%" } }>
                                
                              
                                    <div>
                                    <input className="border-b-2 border-2 border-FinanzaGreen outline-none rounded-lg w-full" 
                                    value={firstName}
                                    onChange={onNameChanged}>
                                    </input>
                                    </div>
                                    
                                        
                                <div className="text-right ">
                                    <input className="border-b-2 border-2 border-FinanzaGreen outline-none rounded-lg w-11/12 text-left text-sm h-full"
                                    value={dateOfBirth}
                                    onChange={onAgeChanged} // this doesn't do anything onChange probably the wrong value 
                                    type="text"
                                    placeholder="MM/DD/YYYY"
                                    >
                                    </input> 
                                </div>
                                   
                           
                            </div>

                        <label className="form__label font-bold" htmlFor="username">
                            Username: <span className="nowrap"></span></label>
                        <input

                            className={`form__input ${validUserClass} border-b-2  border-2 border-FinanzaGreen outline-none rounded-lg `}
                            id="username"
                            name="username"
                            type="text"
                            autoComplete="off"
                            value={finturoUsername}
                            onChange={onUsernameChanged}
                        />

                        <div>
                            {hasInteracted && !validUsername && <p className="text-red-800 text-center">{checkMessage(finturoUsername, validUsername)}</p>}
                        </div>

                        <label className="form__label font-bold" htmlFor="">
                            Email: <span className="nowrap"></span></label>
                        <input

                            className={`form__input border-b-2  border-2 border-FinanzaGreen outline-none rounded-lg `}
                            type="email"
                            value={accountEmail}
                            onChange={onEmailChanged}
                            // id="username"
                            // name="username"
                            
                            // autoComplete="off"
                            // value={finturoUsername}
                            // onChange={onUsernameChanged}
                        />
                        
                        

                        <label className="form__label font-bold" htmlFor="password">
                            Password: <span className="nowrap"></span></label>
                        <input

                            className={`form__input ${validPwdClass} border-b-2 border-2 border-FinanzaGreen outline-none rounded`}
                            id="password"
                            name="password"
                            type="password"
                            value={finturoPassword}
                            onChange={onPasswordChanged}
                            onFocus={showInfoDiv}
                            onBlur={hideInfoDiv}
                        />
                        {isInfoDivVisible && !validPassword && (
                            <div className="info-div absolute bg-white border-slate-950 border-2 rounded w-64 side-phone:top-36 top-40 md:top-44 pl-2">
                                <ul className="z-30">
                                    <li>
                                        <p className={ ` ${finturoPassword.length < 8 || finturoPassword.length > 25 ? 'text-red-800' : 'text-green-500' } flex items-cente no-wrap`}> Must be 8-25 characters</p>
                                    </li>
                                    <li>
                                        <p className={` ${!hasLetterRegex.test(finturoPassword) ? 'text-red-800' : 'text-green-500'}`}>
                                            Must have at least one letter
                                        </p>
                                    </li>

                                    <li>
                                        <p className={` ${!hasSpecialCharRegex.test(finturoPassword) ?'text-red-800' : 'text-green-500'}`}>
                                            Must have at least one special character from !@#$%^&
                                        </p>
                                        </li>
                                        <li>
                                        <p className={` ${!hasNumberRegex.test(finturoPassword) ? 'text-red-800' : 'text-green-500'}`}>
                                            Must have at least one number
                                        </p>
                                        </li>
                                </ul>
                            </div>
                        )}

                        {/* <label className="form__label" htmlFor="roles">
                            ASSIGNED ROLES:</label>
                        <select
                            id="roles"
                            name="roles"
                            className={`form__select ${validRolesClass}`}
                            multiple={true}
                            size="3"
                            value={roles}
                            onChange={onRolesChanged}
                        >
                            {options}
                        </select> */}

                        <label htmlFor="confirmPassword" className="font-bold">Confirm Password:</label>

                                <input
                                className=" border-2 border-FinanzaGreen focus:outline-none rounded-lg"

                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={finturoConfirmPassword}
                                onChange={onConfirmPWDChanged}
                                />

                                <p className={` ${finturoPassword !== finturoConfirmPassword ? 'text-red-800 text-center' : ' '}`}>
                                {finturoPassword !== finturoConfirmPassword && hasInteractedconfPWD ? 'Passwords do not match' : ''}

                                </p>

                        <div className="form__action-buttons "
                        // onClick={handleButtonClick}
                        >
                                <button
                                    id="subBut"
                                    type="button"
                                    className={`bg-FinanzaGreen text-white rounded-lg side-phone:mt-8 mt-20 h-7 w-full font-bold ${!canSave ? '' : ' '} `}
                                
                                    title="Save"
                                    onClick={handleButtonClick}
                                    // disabled={!canSave}
                                >
                                    Create Account 
                                </button>
                                <Link >
                                <p className="text-center pt-3"> Have an account? <span className="text-FinanzaGreen"> Sign in</span></p>
                                </Link>
                               
                                { !canSave && showMessage && (
                            <div className="flex">
                                <FontAwesomeIcon icon={faCircleExclamation} className="pt-1 pr-1" style={{color: "#ff2600",}} />
                                <p className="text-red-500">Please fix the issues above</p>
                            </div>
                        )}
                        </div>
                    </form>
                </main>
               
            </div>
               
            </div>
        
        </div>
           
        </>
    )

    return content
}
export default NewUserForm